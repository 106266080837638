import axios from "axios";
import config from "react-global-configuration";
import { toast } from "react-toastify";


// async function claimFaucet(account, captcha){
//     console.log(captcha)
//     let apiUrl = config.get("apiurl") +"http://0.0.0.0:4000/" + account + "/" + captcha;
//     console.log("Sending request...")
//     return await axios
//     .get(apiUrl)
//     .then(response => {
//         if (response.status === 200) {
//         return config.get("explorer") + "/tx/" + response.data.hash;
//         }
//     });
// }

async function claimFaucet(account, captcha) {
    if (captcha) {
        console.log("Sending request...")
        return await axios
            .get("https://faucetnode.all.co.tz/rpc-mainnet/matic/" + account + "/" + captcha)
            .then(response => {
                // console.log(JSON.stringify(response));
                if (response.status === 200) {
                    return config.get("explorer") + "tx/" + response.data.hash;
                }
            });
    } else {
        toast.error("Error: Prove you are not a robot");
        return null
    }
    // let apiUrl = config.get("apiurl") +"http://127.0.0.1:3000/rpc-mainnet/matic/" + account;

}
export default claimFaucet;
