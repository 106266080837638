import log from './logo.png';
import './App.css';
import packageJson from "../package.json";
import LoadButton from "./LoadButton";
import ReceiveButton from "./ReceiveButton"
import AppExplanations from "./AppExplanations";
import AccountManager from "./controller/accountManager";
import faucetClaim from "./controller/faucet";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "react-global-configuration";
import configuration from './config.json';
import HCaptcha from '@hcaptcha/react-hcaptcha';

config.set(configuration);

const accountManager = new AccountManager();

function App() {
  const [account, setAccount] = useState("Not connected");
  const [balance, setBalance] = useState(0);
  const [txLink, setTxLink] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [firstTime, setFirstTime] = useState(false)

  return (
    <div className="App">
        <ToastContainer hideProgressBar={true} />
        <div className="App-banner">
          <div className='App-container'>
            <p className=""> Powered by</p>
            <img src={log} className="App-log"/>
            
            <p className="App-title"> Alphachain faucet </p>
          </div>
        </div>
      <header className="App-header">
      <div className="Commands">
        <LoadButton
          
          text="connect"
          loadingText="Loading..."
          color="#0e76cc"
          hidden={account !== "Not connected"}
          onClick={() => accountManager.connect().then((account) => {
            if(!account){
              toast.error(`Wrong network: Please select Alphachain network first`)
            }
            else{
              setAccount(account);
              accountManager.getBalance().then((balance) => {setBalance(balance)});
            }
          })}
        />  
        <LoadButton
          text={Number(balance) >= config.get("maxAmount") ? "Balance too high" : "Receive"}
          loadingText="Sending..."
          color="#0e76cc"
          disabled={Number(balance) >= config.get("maxAmount") || captcha === ""}
          hidden={true}
          onClick={() => faucetClaim(account, captcha)
            .then((hash) => {
              toast.success(hash)
              // toast.success("Transaction sent!");
              // setTxLink(hash);
              // setBalance(accountManager.getFormattedBalance(Number(accountManager.balance+config.get("maxAmount")), 18));
              // setCaptcha("");
            })
            .catch((error) => {
              toast.error(`${error.response.data.err.message} 🙅`)})
          }
        />
        <ReceiveButton
          text={Number(balance) >= config.get("maxAmount") ? "Balance too high" : "Receive"}
          loadingText="Sending..."
          color="#0e76c7"
          disabled={Number(balance) >= config.get("maxAmount") || captcha === ""}
          hidden={account === "Not connected"}
          onClick={() => faucetClaim(account, captcha)
            .then(async (hash) => {
              if (hash) {
                toast.success("Transaction sent!");
                setTxLink(hash);
                let newBalance = await accountManager.getBalance(true)
                setBalance(newBalance);
              }
              
              // setCaptcha("");
            })
            .catch((error) => {
              toast.error(`You already have a sufficient balance to use Alphachain network 🙅`)
              // toast.error(`Error ${error}`)
              console.log(error);
            })
          }
        />
      </div>
      <form id="receive" action="" method="POST">
        <HCaptcha
          theme="dark"
          sitekey={config.get("hcaptchasitekey")}
          onVerify={(token,ekey) => {setCaptcha(token)}}
        />
      </form>
      <p hidden={account === "Not connected"}>{account}</p>
      <p hidden={account === "Not connected"}>{"Your balance: " + String(balance)}</p>
      <a hidden={txLink === ""} href={txLink}>{txLink}</a>
      <br></br>
      <AppExplanations></AppExplanations>
      <div className="App-footer">
        <p>
          <a href="https://alphachain.all.co.tz/" target="_blank" rel="noopener noreferrer"> Alphachain Testnet </a> 
          {`${packageJson.version}`}.{" "}
          <a href="">
          
          </a>
        </p>
         <p>
                Developed and maintained by eGA-RIDC, Copyright © 2023 eGA-RIDC
        </p>
      </div>
      </header>
    </div>
  );
}

export default App;
