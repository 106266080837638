import React, { useState } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import ReactMarkdown from "react-markdown";
import "./index.css";

export default function AppExplanations() {
  const [expandedItems, setexpandedItems] = useState([]);

  // In case the user expands a node that is barely visible, we scroll the page to display it fully
  function handleExpand(update) {
    if (update.length > expandedItems.length) {
      const newExpandedItemUUID = update[update.length - 1];
      const itemButtonBottom = document
        .getElementById(`accordion__panel-${newExpandedItemUUID}`)
        .getBoundingClientRect().bottom;
      if (itemButtonBottom > window.innerHeight) {
        window.scrollBy(0, itemButtonBottom - window.innerHeight);
      }
    }
    setexpandedItems(update);
  }

  const whatIsAFaucet_help =
    //eslint-disable-next-line
    " A `Faucet` is a tool that provides a small amount of funds to start using a cryptocurrency without having to mine or buy some. \n\
     It's often a  website with plenty of ads that will send you funds half the time, only after asking you to input your email to send you spam later.  \n\
     `Alphachain` had none for its mainnet, so here's one";
    

  const howMuchCanIGet_help =
    // eslint-disable-next-line
    "Transactions on Alphachain network are `0.000000001 Alp`. The faucet will only send you `5 Alp`";
    // `Plenty enough!`  \n\
    // Transactions on Alphachain network are cheap. Forget Ethereum, forget BSC, we're talking about fractions of a cent for most transactions.  \n\
    // So this faucet will only send you `1000 ALP` - which is enough to help developers, for instance  \n\
    // With `1000 ALP`, you can do `100` basic transactions on Alphachain network ! You can even deposit or withdraw funds on `Aave`, even though it is a pretty expensive transaction (`50$+` on Ethereum, `1$+` on Binance Smart Chain). \n\
    // The goal of this faucet is not to make you rich but just to make the onboarding to Alphachain smoother.  \n\
    // You can use it up to `3 times a day`, for the most clumsy of us 🙄  \n\
    // Feel free to send some spare change at `0x8C5a6C767Ee7084a8C656Acd457Da9561163aE7E` to replenish the faucet once you're rich 🦄

  // const  =
  //   "";
  //   * First bring your assets from Ethereum to Alphachain through [the bridge]  \n\
  //   Then there's a variety of things you can do:  \n\
  //   * Swapping assets on [QuickSwap](https://quickswap.exchange/) or [ComethSwap](https://swap.cometh.io/#/swap), the equivalents of `Uniswap` on Alphachain  \n\
  //   [Paraswap] is also available and will route your swaps through the cheapest path.  \n\
  // //   * Depositing your assets on [Aave](https://app.aave.com/dashboard) or [Curve] to farm some fresh ALP  \n\
  // //   * Enjoy the same functionalities Ethereum has, only with less friction 🦄  \n\
    

  return (
    <Accordion allowZeroExpanded allowMultipleExpanded onChange={handleExpand}>
       <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>What is a Faucet ?</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <ReactMarkdown
            className="Explanations"
            children={whatIsAFaucet_help}
          ></ReactMarkdown>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>How much can I get ?</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <ReactMarkdown
            className="Explanations"
            children={howMuchCanIGet_help}
          ></ReactMarkdown>
        </AccordionItemPanel>
      </AccordionItem>
      {/* <AccordionItem> */}
        {/* <AccordionItemHeading>
          <AccordionItemButton>
            How to earn (much) more alp ?
          </AccordionItemButton>
        </AccordionItemHeading> */}
        {/* <AccordionItemPanel> */}
          {/* <ReactMarkdown
            className="Explanations"
            children={}
          ></ReactMarkdown> */}
        {/* </AccordionItemPanel> */}
      {/* </AccordionItem> */}
    </Accordion>
  );
}
